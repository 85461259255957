<template>
    <div class="popup">
        <div class="popup-header">
            {{ $t('buttons.sendconfirm') }} {{ this.username_by_id(this.contract.user_id) }} @ {{ planning.project_name }}
            <b-button href="javascript:;" variant="primary" @click="generate_company_pdf(contract_id)"
                :title="$t('labels.showconfirm')">
                <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showconfirm') }}
            </b-button>

            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
            <b-container fluid>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.emailsendersname") }}
                    </div>
                    <div class="col-8">
                        <v-select v-if="false" :options="this.activeemailsenders.concat(authemailsenders)" label="name" v-model="sender" />
                        <v-select :options="this.authemailsenders" label="name" v-model="sender" />
                    </div>
                </div>                
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendto") }}
                    </div>
                    <div class="col-8">
                        <v-select :options="this.cc_bcc_users" v-model="to">
                            <template slot="option" slot-scope="option">
                                {{ option.label }}
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.company_contact" icon="address-book" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.document_contact" icon="file" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.invoice_contant" icon="euro-sign" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.sign_contact" icon="pencil-alt" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.location_contact" icon="street-view" />
                
                            </template>
                            </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendcc") }}
                    </div>
                    <div class="col-8">
                        <v-select multiple :options="this.cc_bcc_users" v-model="cc" >
                            <template slot="option" slot-scope="option">
                                {{ option.label }}
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.company_contact" icon="address-book" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.document_contact" icon="file" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.invoice_contant" icon="euro-sign" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.sign_contact" icon="pencil-alt" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.location_contact" icon="street-view" />
                
                            </template>
                    </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendextracc") }}
                    </div>
                    <div class="col-8">
                        <b-input v-model="extra_cc" :placeholder="$t('strings.freeemailfield')" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendbcc") }}
                    </div>
                    <div class="col-8">
                        <v-select multiple :options="this.cc_bcc_users" v-model="bcc" >
                            <template slot="option" slot-scope="option">
                                {{ option.label }}
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.company_contact" icon="address-book" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.document_contact" icon="file" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.invoice_contant" icon="euro-sign" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.sign_contact" icon="pencil-alt" />
                                    <font-awesome-icon style=" margin-right: 1rem;float:right;" v-if="option.location_contact" icon="street-view" />
                
                            </template>
                    </v-select>                            
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.sendextrabcc") }}
                    </div>
                    <div class="col-8">
                        <b-input v-model="extra_bcc" :placeholder="$t('strings.freeemailfield')" />
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("titles.subject") }}
                    </div>

                    <div class="col-8">
                        <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.contactcompanydocuments") }}
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.companydocuments" label="name" v-model="companyselected" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.contactmaindocuments") }}
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.documents" label="name" v-model="selected" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        {{ $t("labels.zzpdocuments") }}
                    </div>

                    <div class="col-8">
                        <v-select multiple :options="this.zzprdocuments" label="name" v-model="zzprselected" />
                    </div>
                </div>              
                <div class="form-row" >
                    <div class="col-3">
                        {{ $t("titles.documents") }}
                    </div>

                    <div class="col-1">
                        ID
                    </div>
                    <div class="col-2">
                    </div>
                    <div class="col" :class="rowClass('ID')">
                        {{$t('labels.expirationdate')}} {{ docdate('ID') }}
                    </div>
                </div>                         
                <div class="form-row" v-if="hasdoc('KvK')">
                    <div class="col-3">
                        <!-- {{ $t("titles.documents") }} -->
                    </div>

                    <div class="col-1">
                        <a :href="docto('KvK')" v-bind:target="'_blank'">KVK</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addKVK" name="checkbox-kvk" value=1
                            unchecked-value=0>KvK</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('KvK')">
                        {{$t('labels.expirationdate')}} {{ docdate('KvK') }}
                    </div>
                </div>
                <div class="form-row" v-if="hasdoc('VCA')">
                    <div class="col-3"></div>
                    <div class="col-1">
                        <a  :href="docto('VCA')" v-bind:target="'_blank'">VCA</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addVCA" name="checkbox-vca" value=1
                            unchecked-value=0>VCA</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('VCA')">
                        {{$t('labels.expirationdate')}} {{ docdate('VCA') }}
                    </div>
                </div>
                <div class="form-row" v-if="hasdoc('AVB')">
                    <div class="col-3"></div>
                    <div class="col-1">
                        <a  :href="docto('AVB')" v-bind:target="'_blank'">AVB</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addAVB" name="checkbox-avb" value=1
                            unchecked-value=0>AVB</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('AVB')">
                        {{$t('labels.expirationdate')}} {{ docdate('AVB') }}
                    </div>
                </div>                
                <div class="form-row" v-if="hasdoc('GPI')">
                    <div class="col-3">
                    </div>
                    <div class="col-1">
                        <a :href="docto('GPI')" v-bind:target="'_blank'">GPI</a>
                    </div>
                    <div class="col-2">
                        <b-form-checkbox v-model="addGPI" name="checkbox-gpi" value=1
                            unchecked-value=0>GPI</b-form-checkbox>
                    </div>
                    <div class="col" :class="rowClass('GPI')">
                        {{$t('labels.expirationdate')}} {{ docdate('GPI') }}
                    </div>
                </div>                                
                <div class="form-row">
                    <div class="col-3">
                    </div>
                    <div class="col-2">
                        <a href="javascript:void(0)"  @click="generate_company_pdf(contract_id)">{{ $t('labels.confirmation') }}</a>
                    </div>                    
                    <div class="col-1">
                        <b-form-checkbox v-model="addOVEREENKOMST" name="checkbox-urenbon" value=1 
                            unchecked-value=0 />
                    </div>                    
                </div>

                <div id="app" :style="ckeditorState(editorData)" >
                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
            </b-container>
        </div>
        <div class="popup-footer">
            <div v-if="!to.id||subject==null||subject==''||editorData==null||editorData==''" class="btn btn-primary">{{ $t("buttons.invalid") }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ $t("buttons.sendcontract") }}</div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import popup_confirm from './PopupConfirm.vue'

import axios from 'axios';

export default {
    name: 'send_confirm_modal',
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        placeholder:this.$t('labels.message')
      },
            sendcontact: 1,
            sendzzpr: 1,
            to: "",
            cc: [],
            bcc: [],
            extra_cc: "",
            extra_bcc: "",
            selected: [],
            companyselected: [],
            zzprselected:[],
            subject: '',
            addKVK: "0",
            addVCA: "0",
            addGPI: "0",
            addAVB: "0",
            addOVEREENKOMST:"1",

            sender:null
        }
    },
    props: {
        contract_id: Number,
    },

    mounted() {
    },
    computed: {

        contract() {
            return this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
        },
        planning() {
            return this.planning_by_id(this.contract.planning_id)
        },
        user() {
            return this.user_by_id(this.contract.user_id)
        },
        company() {
            return this.company_by_id(this.project_by_id(this.contract.project_id).company_id)
        },
        companydocuments() {
            return this.company_by_id(this.project_by_id(this.contract.project_id).company_id).documents
        },
        zzprdocuments() {
            return this.user.documents.filter(d => d.name!='KvK' && d.name!='VCA' && d.name!='GPI')
        },        
        ...mapGetters([
            'planning_by_id',
            'project_by_id',
            'contract_by_id',
            'contracts_metas',
            'contract_new',
            'username_by_id',
            'user_by_id',
            'company_by_id',
            'owners',
            'server_url',
            'activeemailsenders'


        ]),
        ...mapState([
            'users',
            'settings',
            'contracts',
            'current_user',
            'documents',
            'emailsenders',
            'authemailsenders'

        ]),
        company_document_users() {
            let persons = []
            this.company.contactpersons.forEach(aperson => {
                persons.push({ id: aperson, label: this.username_by_id(aperson) })
            });
            return persons
        },
        cc_bcc_users() {
            let persons = []
            this.company.contactpersons.forEach(aperson => {
                if(this.user_by_id(aperson)&&(this.user_by_id(aperson).blacklist==undefined ||this.user_by_id(aperson).blacklist!=1)){
                    persons.push({ id: aperson, label: this.username_by_id(aperson), 
                    company_contact:this.user_by_id(aperson).company_contact == '1',
                    document_contact:this.user_by_id(aperson).document_contact == '1',
                    invoice_contant:this.user_by_id(aperson).invoice_contant == '1',
                    sign_contact:this.user_by_id(aperson).sign_contact == '1',
                    location_contact:this.user_by_id(aperson).location_contact == '1'
                 })
                }
            });
            // this.owners.forEach(aperson => {
            //     let p = persons.filter(p => p.id == aperson.id)
            //     if(p.length==0) {
            //         persons.push({ id: aperson.id, label: this.username_by_id(aperson.id), 
            //         company_contact:this.user_by_id(aperson.id).company_contact == '1',
            //         document_contact:this.user_by_id(aperson.id).document_contact == '1',
            //         invoice_contant:this.user_by_id(aperson.id).invoice_contant == '1',
            //         sign_contact:this.user_by_id(aperson.id).sign_contact == '1',
            //         location_contact:this.user_by_id(aperson.id).location_contact == '1'
            //      })
            //     }
            // });
            return persons
        },


    },
    methods: {
        async save_and_close() {
            // generate contract and urenenbriefjes
            await this.generate_contract_company(this.contract_id);
            //let email=this.user_by_id(this.contract.user_id).email
            console.log(this.to)
            let to_id = this.to.id?this.to.id:this.planning.sign_id
            let name = this.username_by_id(to_id)
            let message = {}
            // message.email = this.user_by_id(this.planning.sign_id).email
            message.email = this.user_by_id(to_id).email
            message.name = name
            message.message = this.editorData
            message.subject = this.subject
            message.sender = this.sender ? this.sender.id : -1
            message.sendermail =  this.sender ? this.sender.email : -1
            message.sendername =  this.sender ? this.sender.name : -1
            message.userdocuments = []
            if(this.addOVEREENKOMST == "1"){
                message.userdocuments.push({ file: 'contracts/opdrachtgever_overeenkomst-' + this.contract_id + ".pdf", name: "Overeenkomst" });
            }
            this.user.documents.forEach(element => {
                if ((this.addKVK == "1" && element.name == "KvK") || (this.addVCA == "1" && element.name == "VCA") || (this.addGPI == "1" && element.name == "GPI")|| (this.addAVB == "1" && element.name == "AVB"))
                    message.userdocuments.push({ file: element.link, name: element.name + " " + name })
            });
            this.zzprselected.forEach(element => {
                message.userdocuments.push({ file: element.link, name: element.name })
            });            
            message.companydocuments = []
            this.companyselected.forEach(element => {
                message.companydocuments.push({ file: element.link, name: element.name })
            });

            message.maindocuments = []
            this.selected.forEach(element => {
                message.maindocuments.push({ file: element.file, name: element.name })

            });
            // add to, cc and bcc
            message.to = []
            // this.to.forEach(element => {
            //     message.to.push(this.user_by_id(element.id).email)
            // })
            message.cc = []
            // copy to sender
            if(this.sender){
                message.cc.push(this.sender.email)
            }             
            this.cc.forEach(element => {
                message.cc.push(this.user_by_id(element.id).email)
            })
            if (this.extra_cc) {
                this.extra_cc.split(",").forEach(element => {
                    message.cc.push(element)
                })
            }
            message.bcc = []
            this.bcc.forEach(element => {
                message.bcc.push(this.user_by_id(element.id).email)
            })
            if (this.extra_bcc) {
                this.extra_bcc.split(",").forEach(element => {
                    message.bcc.push(element)
                })
            }
            // console.log('lets send', message)


            try {
                axios.post('/api/send_confirm_company', message).then((response) => {
                    console.log('succes', response)
                    if(response.data.status && response.data.status!="ok"){
                        this.$modal.show(popup_confirm, {
                        message:this.$t('validation.mailfailed'),
                        header: this.$t('titles.mailfailed'),
                        }, {
                            name: 'popup_confirm',
                            draggable: false,
                            height: 'auto',
                            width: '90%'
                        })                   
                    }
                })
            } catch (err) {
                console.log(err)
            }


            this.$emit('close')
        },
        ...mapActions([
            'add_contract',
            'generate_contract_company'
        ]),
        hasdoc(id) {
            var _hasdoc = false
            this.user.documents.forEach(adoc => {
                if (!_hasdoc) {
                    _hasdoc = (adoc.name == id)
                }
            })
            return _hasdoc

        },
        rowClass(item) {
            if (!item ) return
            var expireDate = null
            this.user.documents.forEach(adoc => {
                if (adoc.name == item){
                    expireDate = new Date(adoc.expires)
                }
            })      
            if(item=="ID")      {
                expireDate = new Date(this.user.identification_endate)
            }
            var today = new Date();
            var diff = (expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
            if ((diff < 2)) {
                return 'status-expires-dialog'
            }
            if ((diff < 31)) {
                return 'status-expires-soon-dialog'
            }      
            return 'status-active-dialog'

        },    
        docdate(id) {
            let ret =""
            if(id=="ID"){
                ret = this.dateToNL(this.user.identification_endate)
            } else {
            this.user.documents.forEach(adoc => {
                if (adoc.name == id){
                    // console.log("found", adoc)
                    ret = this.dateToNL(adoc.expires)
                }
            })
        }
            return ret

        },
        docto(id) {
            var _link = ''
            this.user.documents.forEach(adoc => {
                if ((adoc.name == id)) {
                    _link = adoc.link
                }
            })
            return this.server_url + '/media_' + _link;
        },

    },
}
</script>