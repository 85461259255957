<template>
  <div class="hello">
    <h2 class="modal-title">{{ data.userfullname }} {{ $t("buttons.reviewuser") }} </h2>
    <div v-html="page" />
    <b-row v-if="show">
      <b-col>{{ $t('labels.waardering') }}</b-col>
      <b-col>
        <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="rating"></star-rating>
      </b-col>
    </b-row>
    <b-row v-if="show">
      <b-col>
        {{userAgain }}
      </b-col>
      <b-col>
        <b-form-group>
          <b-form-radio v-model="rehire" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="rehire" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="show">
      <b-col>
        {{ $t('meta.remarks') }}
      </b-col>
    </b-row>
    <b-row v-if="show">
      <b-col>
        <textarea style="width:100%; height:100px"  v-model="remarks"></textarea>
      </b-col>
    </b-row>    
<button v-if="rating>0" class="btn btn-primary" @click="close_and_save">{{ $t( "buttons.saveandclose") }}</button>

</div>
</template>

<script>
import axios from 'axios';
import { mapGetters} from 'vuex'

import StarRating from 'vue-star-rating'


export default {
  name: "reviewuser",
  data() {
    return {
      code:null,
      data:null,
      show:false,
      settings:null,
      rating:0,
      rehire:null,
      remarks:null
    }
  },
  async mounted() {
    this.code = this.$route.params.id
    console.log("mount2", this.$route.params)
    await axios.get('/api/public_settings').then( async (response) =>{
      this.settings = response.data
    })
    await axios.get('/api/review_user/'+this.code)  .then( async (response) => {
      this.data=response.data
      this.show= true
    })
  },
  computed:{
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'tag_by_id',
      'project_by_id',
      'username_by_id',
      'find_planning',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user'
    ]),
    page(){
      let ret = this.settings.reviewuser
      if(this.show) {
        ret = ret.replaceAll(/\[signer\]/gi, this.data.signerfullname)
        ret = ret.replaceAll(/\[user\]/gi, this.data.userfullname)
        ret = ret.replaceAll(/\[project\]/gi, this.data.project_name)
    } else {
      ret = "Bedankt voor uw beoordeling"

    }
      
      return ret
    },
    userAgain(){
      let ret =  this.settings.reviewuseragain
      ret = ret.replaceAll(/\[user\]/gi, this.data.userfullname)
      return ret
    }
  },
  methods: {
    close_and_save(){
      console.log("close_and_save")
      let result = {}
      result.id = 0
      result.user_id= this.data.user_id
      result.signer_id= this.data.signer_id
      result.contract_id= this.data.contract_id
      result.rating= this.rating
      result.rehire= this.rehire=="yes"?1:0
      result.remarks= this.remarks
      console.log(result)
      axios.put("/api/review/" + result.id, result)
      .then(()=>{
        this.show = false
        this.rating = 0
      })
    
    }
  },
  components:{StarRating}

};
</script>

