<template>
  <b-container fluid>
    <template v-if="current_user &&current_user.role == 'admin'">
      <input type="hidden" v-model="refreshcount">

      <!-- Title bar -->
      <div class="title-bar">
        <h1>{{ $t("titles.users") }} ({{totalRows}})</h1>
          <div @click="resetSettings()" class="btn btn-primary">
              <font-awesome-icon icon="undo" /> {{ $t("buttons.reset") }}
          </div>
        <div @click="edit_user({ id: 0 })" class="btn btn-primary">
          <font-awesome-icon icon="plus" /> {{ $t("buttons.adduser") }}
        </div>
      </div>

      <!-- Filter bar -->
      <div class="filter-bar">
        <b-row>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.search')">
              <b-form-input ref="searchbox" id="filter-input" v-model="usersettings.filter" type="search" :placeholder="$t('strings.typetosearch')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.branche')">
              <b-form-select name="branches" class="form-control" v-model="usersettings.currentbranche">
                <option value="-1"> {{ $t("option.all") }}</option>
                <option v-for="(branche, idx) in activebranches" :key="idx" v-bind:value="branche.id">
                  {{ branche["name"] }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.role')">
              <b-form-select v-model="usersettings.currentrole" class="form-control">
                <option value="-1"> {{ $t("option.all") }}</option>
                <option value="user"> {{ $t("option.zzpr") }}</option>
                <option value="contactpersoon">{{ $t("option.contactperson") }}</option>
                <!-- <option value="opdrachtgever">{{ $t("option.client") }}</option> -->
                <option value="admin">{{ $t("option.admin") }}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.addedby')">
              <select name="admin" class="form-control admins" v-model="usersettings.owner">
                <option value="-1"> {{ $t("option.all") }}</option>

                <option v-for="(user, idx) in owners" :key="idx" v-bind:value="user.id">
                  {{ user["firstname"] }} {{ user["middlename"] }} {{ user["lastname"] }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="4" xl>
            <b-form-group :label="$t('labels.tag')">
              <v-select label="tag" :options="tags" class="contactpersoon" v-model="usersettings.selectedtag"></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col  md="2"  v-if="settings.EnableBlacklist">
            <b-form-checkbox v-model="usersettings.showblacklist" name="checkbox-1" value="1" unchecked-value="0">{{
                $t("labels.blacklist")
            }}</b-form-checkbox>
          </b-col>
          <b-col  md="2" >
            <b-form-checkbox @change="()=>{this.$store.dispatch('get_users');}" v-model="usersettings.statusactiveusers" name="checkbox-1" value="1" unchecked-value="0">{{
                $t("labels.statusactiveusers")
            }}</b-form-checkbox>
          </b-col>
          
        </b-row>
      </div>

      <!-- Table -->
      <b-table sort-icon-left :items="branche_users" :fields="fields" :current-page="usersettings.currentPage" :per-page="perPage"
        :filter="usersettings.filter" :filter-included-fields="filterOn" :filter-function="filterTable" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass" @filtered="onFiltered"
        @row-clicked="onUserClicked">
        <template #cell(branche_id)="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span>
          <span v-html='brancheName(row.item.id)' :title="brancheTitle(row.item.id)"></span>
        </template>
        <template #cell(lastname)="row">
          {{username_by_id(row.item.id)}}<br/>
          <strong>{{$t('option.'+row.item.role)}} </strong>
          <font-awesome-icon icon="times" color="red"  v-if="row.item.role=='contactpersoon' && companies.filter(c => c.contactpersons.indexOf(row.item.id)!=-1).length==0"/>
          <font-awesome-icon icon="check" color="green" v-if="row.item.role=='contactpersoon' && companies.filter(c => c.contactpersons.indexOf(row.item.id)!=-1).length>0"/>
          <div v-if="false && row.item.role=='contactpersoon'" v-html="companies_contacts(row.item)"></div>
        </template>
        <template #cell()="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span> {{ row.value }}
        </template>
        <template #cell(pulldown)="row">
          <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
            <b-dropdown-item v-if="has_projectscontracts(row.item.id)==false" @click="confirm_delete_user(row.item.id)" :title="$t('labels.deleteuser')">
              <font-awesome-icon icon="trash" /> {{ $t('labels.deleteuser') }}
            </b-dropdown-item>
            <b-dropdown-item @click="edit_user(row.item)">
              <font-awesome-icon icon="cog" /> {{ $t('labels.edituser') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="settings.boekhoudprogramma=='boekhoudgemak'&& row.item.role=='user'" @click="send_to_boekhoudgemak(row.item)">
              <font-awesome-icon icon="save" /> {{ $t('labels.sendtoboekhoudgemak') }}
            </b-dropdown-item>            
            <b-dropdown-item v-if="settings.boekhoudprogramma=='snelstart'&& row.item.role=='user'" @click="send_to_snelstart(row.item)">
              <font-awesome-icon icon="save" /> {{ $t('labels.sendtosnelstart') }}
            </b-dropdown-item>            
            <b-dropdown-item @click="set_password(row.item)">
              <font-awesome-icon icon="lock" /> {{ $t('labels.setpassword') }}
            </b-dropdown-item>               
          </b-dropdown>
          <div>
            <font-awesome-icon class="table-contacts-roles"
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.company_contact == '1'"
              icon="address-book" :title="$t('labels.company_contact')" />
            <font-awesome-icon class="table-contacts-roles"
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.document_contact == '1'"
              icon="file" :title="$t('labels.document_contact')" />
            <font-awesome-icon class="table-contacts-roles"
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.invoice_contant == '1'"
              icon="euro-sign" :title="$t('labels.invoice_contant')" />
            <font-awesome-icon class="table-contacts-roles"
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.sign_contact == '1'"
              icon="pencil-alt" :title="$t('labels.sign_contact')" />
            <font-awesome-icon class="table-contacts-roles"
              v-if="(row.item.role == 'contactpersoon' || row.item.role == 'opdrachtgever') && row.item.location_contact == '1'"
              icon="street-view" :title="$t('labels.location_contact')" />

            <font-awesome-icon class="table-contacts-roles" v-if="hasbirthday(row.item)"
              icon="birthday-cake" title="$t('labels.hasbirthday')" />
            <a v-if="checkcallat(row.item) == 2" href="javascript:;" class="action-item"
              @click="callUserModal(row.item.id)" :title="$t('labels.calluser')">
              <font-awesome-icon style="color:red" icon="phone" />
            </a>
          </div>
        </template>
        <template #cell(photo)="row">
          <img class="profile-photo" :src="profileto(row.item.id)" />
        </template>
        <template #cell(email)="row">
          <span class="mobile-span">{{ $t('tableheaders.' + row.field.key) }}</span>
          <a :href=mailto(row.value)>{{ row.value }}</a>
        </template>
        <template #cell(mobile)="row">
          <a :href=phoneto(row.value)>{{ row.value }}</a>
        </template>
      </b-table>
      <b-pagination v-model="usersettings.currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
      <BSSetPassword :user_id="selectedUser"/>

    </template>
  </b-container>

</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import popup_modal from './modals/PopupModal.vue'
import BSSetPassword from './modals/BSSetPassword'

export default {
  name: 'usersscreen',
  data() {
    return {
      // currentPage: 1,
      perPage: 10,
      sortBy: 'lastname',
      sortDesc: false,
      sortDirection: 'desc',
      // filter: null,
      filterOn: [],
      // currentbranche: -1,
      // owner: -1,
      // currentrole: -1,
      // showblacklist: 0,
      // selectedtag: null,
      selectedID: null,
      selectedUser:null,
      refreshcount:0

    }
  },
  mounted() {
    this.usersettings.showblacklist = this.showBlacklist
    if(this.$refs["searchbox"]){
      this.$refs["searchbox"].focus()
    }    
  },
  watch: {
    showblacklist: function () {
      this.$store.commit('showBlacklist', this.showblacklist);
    },
    users(){
      // console.log(" watched users",this.users.length)
      this.refreshcount++
    }
  },
  computed: {
    totalRows() {
      return this.branche_users.length
    },
    branche_users() {
      if (this.users && this.usersettings.currentbranche == -1 && this.usersettings.currentrole == -1) {
        if (this.usersettings.showblacklist == 1) {
          return this.checkTags(this.users)
        } else {
          return this.checkTags(this.users.filter(user => { return user.blacklist==0 || user.blacklist==undefined}))
        }
      }
      return this.checkTags(this.users.filter(user => {
        return (user.branche_id == this.usersettings.currentbranche || this.checkExtraBranche(user,this.usersettings.currentbranche )|| this.usersettings.currentbranche == -1) &&
               (this.usersettings.currentrole == -1 || user.role == this.usersettings.currentrole || user.role=="opdrachtgever" && this.usersettings.currentrole =="contactpersoon") &&
               !(this.usersettings.showblacklist == 0 && user.blacklist == 1)

      }))

    },
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'lastname', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'rating', label: this.$i18n.t('meta.waardering'), sortable: true, sortDirection: 'desc' },
        { key: 'mobile', label: this.$i18n.t('meta.phone'), },
        { key: 'email', label: this.$i18n.t('meta.email'), sortable: false, sortDirection: 'desc' },
        { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },

      ]
    },
    ...mapState([
      'users',
      // 'branches',
      // 'avatars',
      'planning',
      'workfields',
      'tags',
      'current_user',
      'showBlacklist',
      'companies',
      'projects',
      'usersettings'


    ]),
    ...mapGetters([
      'branche_by_id',
      'user_by_id',
      'user_photo_by_id',
      'activebranches',
      'username_by_id',
      'confirm_modal',
      'owners',
      'find_last_planning_user'
    ]),

  },
  methods: {
    resetSettings(){
          this.$store.commit('usersettings',{            
            filter:null,
            currentbranche:-1,
            currentrole:-1,
            owner:-1, 
            selectedtag:null,
            currentPage:1,
            showblacklist:0,
            statusactiveusers:1           
           },
          )  
        },
    filterTable(row, fil) {
      let filter = fil.toLowerCase()
      if (
        (row.email && row.email.toLowerCase().includes(filter)) ||
        (row.fullname && row.fullname.toLowerCase().includes(filter)) ||
        (row.firstname && row.firstname.toLowerCase().includes(filter)) ||
        (row.firstname && row.lastname && (row.firstname.toLowerCase()+ " "+ row.lastname.toLowerCase()).includes(filter)) ||
        (row.lastname && row.lastname.toLowerCase().includes(filter)) ||
        (row.mobile && row.mobile.includes(filter))||
        (row.companyname && row.companyname.toLowerCase().includes(filter))) {
        return true;
      } else {
        return false;
      }
    },
    ...mapActions([
      'delete_user',
      'save_user'
    ]),
    has_projectscontracts(id){
      let hascontracts = this.planning.filter(p =>{
        return p.pointcontact_id==id || p.sign_id==id ||p.contact_id==id
      })
      let has_projects = this.projects.filter(p => p.user_id == id )
      return (hascontracts && hascontracts.length>0) || (has_projects && has_projects.length>0)
    },
    confirm_delete_user(id) {
      this.selectedID = id

      let hascontracts = this.planning.filter(p =>{
          return p.pointcontact_id==id || p.sign_id==id
      })
      let has_projects = this.projects.filter(p => p.user_id == id )
      let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(id) + this.$i18n.t('labels.confirmdeletetail')
      let alert = ""
      if(hascontracts && hascontracts.length>0){
        alert ='Gebruiker wordt vermeld in contracten!'
      }
      if(has_projects && has_projects.length>0){
        if(alert==""){
          alert ='Gebruiker is eigenaar van projecten!'  
        } else {
          alert ='Gebruiker is eigenaar van projecten en wordt vermeld in contracten!'
        }
      }
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
        alert:alert
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_user(this.selectedID);
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].id == this.selectedID) {
            this.users.splice(i, 1);
          }
        }
      }
    },
    onUserClicked(row) {
      this.$router.push({ path: '/user/' + row.id });

    },
    send_to_boekhoudgemak(item){
        this.update_crediteur(item.id)
    }, 
    send_to_snelstart(item){
        this.update_crediteur_snelstart(item.id)
    }, 
    set_password(item){
      // console.log(item)
      this.selectedUser = item.id
      this.$bvModal.show('BSSetPasswordID')

    },     
    checkTags(items) {
      if (this.usersettings.selectedtag == null) {
        return this.checkowner(items)
      }
      return this.checkowner(items.filter(item => 
        item.tags.some(tag => tag.tag_id == this.usersettings.selectedtag.id)
      ));
    },
    checkowner(items) {
      if (this.usersettings.owner == -1) {
        return items
      }
      return items.filter(item => item.owner_id==this.usersettings.owner);

    },
    companies_contacts(cp){
      var ret =""
      this.companies.filter(c => c.contactpersons.indexOf(cp.id)!=-1).forEach(c=>{
        ret+= '<a href="/#/company/' + c.id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + c.company_name + '</a><br/>'
      })
      
      return ret

    },
    onFiltered() {
      //    this.totalRows = filteredItems.length
      this.usersettings.currentPage = 1
    },
    sort_role(users) {
      users.sort((a, b) => a.role > b.role && 1 || -1)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      //difference in days
      let timediff = (this.find_last_planning_user(item.id) - new Date()) / (1000 * 60 * 60 * 24)

      if (item.blacklist && item.blacklist == 1) {
        return 'status-blacklisted'
      } else {
        if (timediff > 0 || timediff > -7) { return 'status-active' }
        if (timediff < -7 && timediff > -90) { return 'status-inactive' }
        if (timediff < -90 && timediff > -356) { return 'status-inactive2' }
        if (timediff < -356 && timediff > -712) { return 'status-inactive3' }
        if (timediff < -712 && timediff > -7120) { return 'status-inactive4' }
        if (timediff < -7120) { return 'status-inactive5' }
      }
    },
    edit_user(user) {
      if (user.id == 0) {
        this.$store.commit('new_user', {
          id: 0,
          branche_id: 1,
          workfields: [],
          role:'user',
          owner_id: this.current_user.id,
          active: 1,
          language: "nl",
          country: "NL",
          notifications: 1,
          newsletter: 1,
          mail: 1,
          sms: 1,
          inapp: 1,
          company_contact: 0,
          invoice_contant: 0,
          document_contact: 0,
          sign_contact: 0,
          location_contact: 0,
          isemployee:0
        })

      }
      this.editUserModal(user.id)
    },
    brancheName(value) {
      // console.log(value)
      var user = this.user_by_id(value)
      const branch = this.branche_by_id(user.branche_id);
      var ret = ""
      if (branch) {
        let wfc=0

        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == user.branche_id) {
            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1 && this.workfields[i].active) {
              wfc++
            }
          }
        }
        if(wfc>0){
          ret += "<b>" + branch.name + "</b> ("+wfc+")<br>"

        } else{
          ret += "<b>" + branch.name + "</b><br>"

        }

      }
      return ret
    },
    brancheTitle(value) {
      // console.log(value)
      var user = this.user_by_id(value)
      const branch = this.branche_by_id(user.branche_id);
      var ret = ""
      if (branch) {
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == user.branche_id) {
            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1 && this.workfields[i].active) {
              ret += this.workfields[i]["name"] + "\n"
            }
          }
        }

      }
      return ret
    },
    mailto(id) {
      return "mailto:" + id;
    },
    phoneto(id) {
      return "tel:" + id;
    },
    hasbirthday(user) {
      if (user.birthday != null) {
        let birthDate = new Date(user.birthday)
        var today = new Date();
        let checkbd = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
        var diff = (checkbd.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if ((diff > -1 && diff < 7)) {
          return true
        }
      }
      return false
    },
    checkcallat(user) {
      if (user.callat == null) {
        return 0
      } else {
        let calldate = new Date(user.callat)
        var today = new Date();
        var diff = (calldate.getTime() - today.getTime()) / (1000 * 3600 * 24)
        if (calldate < today || diff < 14) {

          return 2
        }
      }
      return 1
    },

  },
  components:{BSSetPassword},


}
</script>
