
<template>
  <b-container>
  <b-table :items=this.data :fields=fields>
    <!-- {{ data }} -->
    <template #cell(rating)="row">
        <star-rating :read-only="true" v-bind:increment="0.5" v-bind:star-size="30" v-model="row.item.rating"></star-rating>
      </template>
      <template #cell(sign_id)="row">
        <p v-html="userinfo(row)"  />

      </template>
      <template #cell(sent)="row">
      {{dateToNL(row.item.sent)}}

      </template>      
      <template #cell(contract_id)="row">
        {{contactname(row)}}
      </template>
    </b-table>

  </b-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import StarRating from 'vue-star-rating'

export default {
  name: 'userreviewsscreen',
  data() {
    return {
      tag_id: null,
      selectedtag: null,
      data:null
    }
  }, 
async mounted(){
    console.log("mounted ")
    await  axios.get('/api/review/all')
    .then((response) => {
      console.log(response)
      this.data = response.data
    })

  },
  props: {
    user_id: Number
  },
  computed: {
    fields() {
      return [
        {key:'sent', label:this.$i18n.t('labels.datecreated')},
        { key: 'sign_id', label: this.$i18n.t('labels.name'), sortable: true, sortDirection: 'desc' },
        { key: 'contract_id', label: this.$i18n.t('labels.projectname'), sortable: true, sortDirection: 'desc' },
        { key: 'rating', label: this.$i18n.t('tableheaders.rating') },
        { key: 'rehire', label: this.$i18n.t('labels.actions') },
        { key: 'remarks', label: this.$i18n.t('meta.remarks') },
      ]
    },
    user() {
      return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      "tag_by_id",
      'username_by_id'
    ]),
    ...mapState([
      'users',
      'branches',
      'avatars',
      'contracts',
      'tags',
      'planning'
    ]),

  },
  methods:{
    userinfo(row) {
      // console.log("ui", row.item)
      return '<a href="/#/user/' + row.item.signer_id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.signer_id) + '</a><br/>' 
    },
    contactname(row){
      // console.log("name",row.item)
      if(row.item && this.contracts.filter(c=>c.id==row.item.contract_id).length>0){
        if(this.planning.filter(p =>p.id ==this.contracts.filter(c=>c.id==row.item.contract_id)[0].planning_id).length>0 && this.contracts.filter(c=>c.id==row.item.contract_id)[0].planning_id){
           return this.planning.filter(p =>p.id ==this.contracts.filter(c=>c.id==row.item.contract_id)[0].planning_id)[0].project_name
        } else{
          return " "
        }
      } else {
        return ""
      }

    }
  },
  components:{StarRating },

}    
</script>
