<template>
  <div class="popup">
      <div class="popup-header">
          {{ $t('titles.newsletter') }} {{ user_set.length }} {{ $t('titles.users') }}
          <div class="popup-close" @click="$emit('close')">
              <font-awesome-icon icon="times" />
          </div>
      </div>
      <div class="popup-body">
        
          <b-container fluid>
              <div class="form-row">
                  <div class="col-3">
                      {{ $t("labels.emailsendersname") }}
                  </div>
                  <div class="col-8">
                      <v-select :options="this.authemailsenders" label="name" v-model="sender" />
                  </div>
              </div>                    
              <div class="form-row">
                  <div class="col-3">
                    {{ $t("titles.subject") }}
                  
                  </div>

                  <div class="col-8">
                      <b-input  :state="subject!=null&&subject!=''" v-model="subject" required :placeholder='$t("titles.subject")' />
                  </div>
              </div>
              <div class="form-row">
                <div class="col-3">{{ $t('titles.newsletter') }}</div>
                <div class="col-8">
                  <select name="licenses" class="form-control admins" v-model="current_newsletter">
                    <option v-for="option in newsletters" :value="option.id" :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>

                </div>
              </div>
          </b-container>
      </div>
      <div class="popup-footer">
          <div @click="save_and_close" class="btn btn-primary"><font-awesome-icon icon="envelope" /> {{ $t("buttons.sendnewsletter") }}</div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios';
// const querystring = require("querystring");

export default {
  name: 'newsletter_search_modal',
  data() {
      return {
        current_newsletter:0,
        subject: "",
        selected: [],
        sender: 0,
        editdata:""
      }
  },
  props: {
      user_set: Array,
  },

  mounted() {
    this.current_newsletter = this.newsletters[0].id

  },
  computed: {
      ...mapGetters([
          'planning_by_id',
          'project_by_id',
          'contract_by_id',
          'contracts_metas',
          'contract_new',
          'username_by_id',
          'user_by_id',
          'company_by_id',
          'activeemailsenders',
          'newsletter_by_id'


      ]),
      ...mapState([
          'users',
          'settings',
          'contracts',
          'current_user',
          'documents',
          'authemailsenders',
          'newsletters'
  

      ])
  },
  methods: {
      async save_and_close() {
        let index = 0;
        await axios.get("/api/media_"+this.newsletter_by_id(this.current_newsletter).file).then(async response => {
        this.editdata = response.data
        // replace []

        // resolve({template: response.data})
        while (index<this.user_set.length){
          let message = {}
          message.email = this.user_set[index].email
          message.name = this.user_set[index].fullname
          this.editdata = response.data
          this.editdata = this.editdata.replaceAll(/\[first_name\]/gi, this.user_set[index].firstname)
          this.editdata = this.editdata.replaceAll(/\[voornaam\]/gi, this.user_set[index].firstname)

          message.message = this.editdata
          message.subject = this.subject
          message.sender = this.sender ? this.sender.id : -1
          message.sendermail =  this.sender ? this.sender.email : -1
          message.sendername =  this.sender ? this.sender.name : -1
          let unsuburl = await axios.get('/api/unsubscribe_url/?id='+this.user_set[index].id)
          message.message+='<span style="width: 100%;text-align: center">Klik op de <a href="'+unsuburl.data+'">link</a> om je af te melden van de nieuwsbrief</span>'

          // console.log(message, index)
          if(this.user_set[index].newsletter==1) {
            try {
              await axios.post('/api/msal/MSAL_newsletter', message).then((response) => {
                    console.log('succes', response)
                    index ++
                })
            } catch (err) {
                console.log(err)
            }          
          }else{
            console.log( "newsletter disabled, skip ",this.user_set[index].firstname)
            index ++
          }

        }
      })        
      this.$emit('close')

/*          let email = this.user.email
          let name = this.user.fullname
          let message = {}
          message.email = email
          message.name = name
          message.message = this.editorData
          message.subject = this.subject


          */
      },
      ...mapActions([

      ]),

  },
}
</script>