<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
        <h1>{{$t('buttons.financesettings')}}</h1>
        <b-form-group  :label="$t('buttons.financesettings')">
            <b-form-radio @change="setTab()" v-model="settings.boekhoudprogramma" value="boekhoudgemak" >
                {{$t('titles.boekhoudgemak')}}</b-form-radio>
                <b-form-radio @change="setTab()" v-model="settings.boekhoudprogramma"  value="snelstart" >
                    {{$t('titles.snelstart')}}</b-form-radio>
                    <b-form-radio @change="setTab()" v-model="settings.boekhoudprogramma"  value="eboekhouden" >
                        {{$t('titles.eboekhouden')}}</b-form-radio>                    
        </b-form-group>
                            
        <hr>
        <b-tabs v-model="tabIndex">
            <b-tab :active="tabIndex==0">
                <template v-slot:title>{{$t('titles.boekhoudgemak')}}</template>

                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.database") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.database" />
                    </div>
                </div>
                <hr>
                <h2>{{$t('labels.purchase')}}</h2>
                <hr>
                <h3>{{$t('labels.vatshifted')}}</h3>
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchaseshiftlogbook" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchaseshiftledgeraccount" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatcode") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchaseshiftvatcode" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatscenario") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchaseshiftvatscenario" />
                    </div>
                </div>
                <h3>{{$t('labels.vatnotshifted')}}</h3>
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftlogbook" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftledgeraccount" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatcode") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftvatcode" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatscenario") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftvatscenario" />
                    </div>
                </div>
                <h3>{{$t('labels.vatnotshifted')}} {{$t('labels.notNL')}} </h3>
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftEUlogbook" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftEUledgeraccount" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatcode") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftEUvatcode" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatscenario") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.purchasenotshiftEUvatscenario" />
                    </div>
                </div>
                <hr>

                <h2>{{$t('labels.sale')}}</h2>
                <hr>
                <h3>{{$t('labels.vatshifted')}}</h3>
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.saleshiftlogbook" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.saleshiftledgeraccount" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatcode") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.saleshiftvatcode" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatscenario") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.saleshiftvatscenario" />
                    </div>
                </div>
                <h3>{{$t('labels.vatnotshifted')}}</h3>
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.salenotshiftlogbook" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.salenotshiftledgeraccount" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatcode") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.salenotshiftvatcode" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.vatscenario") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.salenotshiftvatscenario" />
                    </div>
                </div>
                <hr>
                <h3>{{$t('titles.counters')}}</h3>        
                <div class="form-row">
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.invoice") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.invoice_counter" />
                    </div>
                    <div class="col-2 text-right">
                        <label for=""> {{ $t("labels.logbook") }}</label>
                    </div>
                    <div class="col-1">
                        <b-form-input type="text" class="form-control" v-model="settings.boekstukcounter" />
                    </div>
                </div>
            </b-tab>
            <b-tab :active="tabIndex==1">
                    <template v-slot:title>Snelstart</template>
                    <hr>
                    <b-form-checkbox  v-model="settings.SyncCrediteuren">{{ $t("labels.snelstartsynccrediteuren") }}</b-form-checkbox>
                    <b-form-checkbox  v-model="settings.SyncDebiteuren">{{ $t("labels.snelstartsyncdebiteuren") }}</b-form-checkbox>
                    <hr>

                    <h2>{{$t('labels.purchase')}}</h2>
                    <hr>
                    <h3>{{$t('labels.vatshifted')}}</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.purchaseshiftledgeraccountss" />
                        </div>

                    </div>
                    <h3>{{$t('labels.vatnotshifted')}}</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.purchasenotshiftledgeraccountss" />
                        </div>
                    </div>
                    <h3>KOR</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.purchasekors" />
                        </div>
                    </div>                
                    <h3>{{$t('labels.vatnotshifted')}} {{$t('labels.notNL')}} </h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.purchasenotshiftEUledgeraccountss" />
                        </div>
                    </div>
                    <hr>

                    <h2>{{$t('labels.sale')}}</h2>
                    <hr>
                    <h3>{{$t('labels.vatshifted')}}</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.saleshiftledgeraccountss" />
                        </div>
                    </div>
                    <h3>{{$t('labels.vatnotshifted')}}</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="snelstartgrootboek" class="contactpersoon" v-model="settings.salenotshiftledgeraccountss" />
                        </div>
                    </div>
                    <hr>
                    <h3>{{$t('titles.counters')}}</h3>        
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.invoice") }}</label>
                        </div>
                        <div class="col-1">
                            <b-form-input type="text" class="form-control" v-model="settings.invoice_counter" />
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.logbook") }}</label>
                        </div>
                        <div class="col-1">
                            <b-form-input type="text" class="form-control" v-model="settings.boekstukcounter" />
                        </div>
                    </div>
            </b-tab>
            <b-tab :active="tabIndex==2">
                <template v-slot:title>{{$t('titles.eboekhouden')}}</template>
                <hr>
                <h2>{{$t('labels.purchase')}}</h2>
                    <hr>
                    <h3>{{$t('labels.vatshifted')}}</h3>{{  }}
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudengrootboek" class="contactpersoon" v-model="settings.eboekhoudpurchaseshiftledgeraccountss" />
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.vatcode") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenvats" class="contactpersoon" v-model="settings.eboekhoudpurchaseshiftvatCode" />
                        </div>
                    </div>
                    <h3>{{$t('labels.vatnotshifted')}}</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudengrootboek" class="contactpersoon" v-model="settings.eboekhoudpurchasenotshiftledgeraccountss" />
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.vatcode") }} 9%</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenvats" class="contactpersoon" v-model="settings.eboekhoudpurchasenotshiftvatCode9" />
                        </div>       
                        <div class="col-2 text-right">
                        </div>
                        <div class="col-4">
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.vatcode") }} 21%</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenvats" class="contactpersoon" v-model="settings.eboekhoudpurchasenotshiftvatCode21" />
                        </div>                                             
                    </div>
                    <h3>KOR</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudengrootboek" class="contactpersoon" v-model="settings.eboekhoudpurchasekors" />
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.vatcode") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenvats" class="contactpersoon" v-model="settings.eboekhoudpurchasekorsvatCode" />
                        </div>                             
                    </div>                
                    <h3>{{$t('labels.vatnotshifted')}} {{$t('labels.notNL')}} </h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.ledgeraccount") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudengrootboek" class="contactpersoon" v-model="settings.eboekhoudpurchasenotshiftEUledgeraccountss" />
                        </div>
                        <div class="col-2 text-right">
                            <label for=""> {{ $t("labels.vatcode") }}</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenvats" class="contactpersoon" v-model="settings.eboekhoudpurchasenotshiftEUvatCode" />
                        </div>                             
                    </div>
                    <hr>   
                    <h3>Factuur template</h3>
                    <div class="form-row">
                        <div class="col-2 text-right">
                            <label for=""> Template</label>
                        </div>
                        <div class="col-4">
                            <v-select label="description" :options="eboekhoudenfactuurtemplates" class="contactpersoon" v-model="settings.eboekhoudenfactuurtemplate" />
                        </div>
                    </div>                     
            </b-tab>
            <b-tab>
                <template v-slot:title>Finqle</template>
                <div @click="sync_finqle" class="btn btn-primary">
                    <font-awesome-icon icon="plus" /> Finqle Synchoniseren
                </div>
                <span v-html ="synclog"></span>
            </b-tab>
        </b-tabs>
        <hr>
        <div @click="save_settings" class="btn btn-primary">
            <font-awesome-icon icon="plus" /> {{$t("buttons.savesettings")}}
        </div>
        <h2>{{ $t("validation.resetuserinvoicecounter") }}</h2>
        <div @click="resetinvoicecounter" class="btn btn-primary" >
            <font-awesome-icon icon="exclamation" color="red" /> {{ $t("buttons.resetinvoicecounter") }}
        </div>

    </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import popup_modal from './modals/PopupModal.vue'

export default {
    name: 'settings-screen',
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', '-', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      },
            subject: "",
            selected: [],
            boekhoudprogramma:"",
            tabIndex:null,
            snelstartgrootboek:[],
            eboekhoudengrootboek:[],
            eboekhoudenfactuurtemplates:[],
            eboekhoudenvats:["HOOG_VERK_21","LAAG_VERK_9","VERL_VERK","VERL_VERK_L9","AFW","BU_EU_VERK","BI_EU_VERK","BI_EU_VERK_D","AFST_VERK","LAAG_INK_9","HOOG_INK_21","VERL_INK","AFW_VERK","BU_EU_INK","BI_EU_INK","GEEN"],
            inkoopbtw:null,
            selectedtag:null,
            synclog:""
        }
    },
    mounted(){
        this.$nextTick(()=> this.setTab())
    },

    computed: {

        ...mapState([
            'settings',
            'users',
            'contracts',
            'hours',
            'tags',
            'companies'

        ]),
        ...mapGetters([
            'server_url',
            'username_by_id',
            'user_by_id',
            'project_by_id',
            'contract_by_id',
            'company_by_id',
            'factuur_by_id',
            'confirm_modal',
            'planning_by_id',
            'createAny'
        ]),
    },
    methods: {
        ...mapActions([
            'save_company'
        ]),
        resetinvoicecounter(){
            let message = "Weet je zeker dat je de factuurtelling van alle zzp-ers op 0 wilt zetten?"
            this.$modal.hide('popup_modal')
            this.$modal.show(popup_modal, {
                message: message,
            }, {
                name: 'popup_modal',
                draggable: false,
                height: 'auto',
                width: '500px'
            }, {
                'before-close': this.modalClose
            })

            this.$emit('close')
        },
        modalClose() {
            if (this.confirm_modal.id == 1) {
             axios.get('/api/user/reset_userinvoicecounter');
              }     
       },
        save_settings() {
            try {
                axios.patch('/api/settings/',
                    {
                        purchaseshiftlogbook: this.settings.purchaseshiftlogbook,
                        purchaseshiftledgeraccount: this.settings.purchaseshiftledgeraccount,
                        purchaseshiftvatcode: this.settings.purchaseshiftvatcode,
                        purchaseshiftvatscenario: this.settings.purchaseshiftvatscenario,
                        purchasenotshiftlogbook: this.settings.purchasenotshiftlogbook,
                        purchasenotshiftledgeraccount: this.settings.purchasenotshiftledgeraccount,
                        purchasenotshiftvatcode: this.settings.purchasenotshiftvatcode,
                        purchasenotshiftvatscenario: this.settings.purchasenotshiftvatscenario,
                        saleshiftlogbook: this.settings.saleshiftlogbook,
                        saleshiftledgeraccount: this.settings.saleshiftledgeraccount,
                        saleshiftvatcode: this.settings.saleshiftvatcode,
                        saleshiftvatscenario: this.settings.saleshiftvatscenario,
                        salenotshiftlogbook: this.settings.salenotshiftlogbook,
                        salenotshiftledgeraccount: this.settings.salenotshiftledgeraccount,
                        salenotshiftvatcode: this.settings.salenotshiftvatcode,
                        salenotshiftvatscenario: this.settings.salenotshiftvatscenario,
                        purchasenotshiftEUlogbook: this.settings.purchasenotshiftEUlogbook,
                        purchasenotshiftEUledgeraccount: this.settings.purchasenotshiftEUledgeraccount,
                        purchasenotshiftEUvatcode: this.settings.purchasenotshiftEUvatcode,
                        purchasenotshiftEUvatscenario: this.settings.purchasenotshiftEUvatscenario,
                        purchasekors:this.settings.purchasekors,
                        database: this.settings.database,
                        invoice_counter:parseInt(this.settings.invoice_counter),
                        boekstukcounter:parseInt(this.settings.boekstukcounter),
                        boekhoudprogramma:this.settings.boekhoudprogramma
                    },
                )
                .then((response) => {
                    console.log(response)
                })
                if(this.settings.boekhoudprogramma=="eboekhouden"){
                    axios.patch('/api/settings/',
                    {
                        eboekhoudpurchaseshiftledgeraccountss: this.settings.eboekhoudpurchaseshiftledgeraccountss,
                        eboekhoudpurchasenotshiftledgeraccountss: this.settings.eboekhoudpurchasenotshiftledgeraccountss,
                        eboekhoudpurchasekors: this.settings.eboekhoudpurchasekors,
                        eboekhoudpurchasenotshiftEUledgeraccountss: this.settings.eboekhoudpurchasenotshiftEUledgeraccountss,

                        eboekhoudpurchaseshiftvatCode: this.settings.eboekhoudpurchaseshiftvatCode,
                        eboekhoudpurchasenotshiftvatCode9: this.settings.eboekhoudpurchasenotshiftvatCode,
                        eboekhoudpurchasenotshiftvatCode21: this.settings.eboekhoudpurchasenotshiftvatCode,
                        eboekhoudpurchasekorsvatCode: this.settings.eboekhoudpurchasekorsvatCode,
                        eboekhoudpurchasenotshiftEUvatCode: this.settings.eboekhoudpurchasenotshiftEUvatCode,

                        invoice_counter:parseInt(this.settings.invoice_counter),
                        boekstukcounter:parseInt(this.settings.boekstukcounter),
                        boekhoudprogramma:this.settings.boekhoudprogramma,
                        eboekhoudenfactuurtemplate:this.settings.eboekhoudenfactuurtemplate
                    },
                    )
                    .then((response) => {
                        console.log(response)
                    })

                }
                if(this.settings.boekhoudprogramma=='eboekhouden'){
                    axios.patch('/api/settings/',
                    {
                        purchaseshiftledgeraccountss: this.settings.purchaseshiftledgeraccountss,
                        purchasenotshiftledgeraccountss: this.settings.purchasenotshiftledgeraccountss,
                        saleshiftledgeraccountss: this.settings.saleshiftledgeraccountss,
                        salenotshiftledgeraccountss: this.settings.salenotshiftledgeraccountss,
                        purchasenotshiftEUledgeraccountss: this.settings.purchasenotshiftEUledgeraccountss,
                        invoice_counter:parseInt(this.settings.invoice_counter),
                        boekstukcounter:parseInt(this.settings.boekstukcounter),
                        boekhoudprogramma:this.settings.boekhoudprogramma,
                        SyncCrediteuren:this.settings.SyncCrediteuren,
                        SyncDebiteuren: this.settings.SyncDebiteuren
                    },
                    )
                    .then((response) => {
                        console.log(response)
                    })

                }
            } catch (err) {
                console.log(err)
            }
        },
        async setTab(){
            if(this.settings.boekhoudprogramma=="snelstart"){
                this.tabIndex = 1
                await axios.get('/api/snelstart_grootboeken')
                .then((response) => {
                    this.snelstartgrootboek = []
                    response.data.forEach(regel =>{
                        this.snelstartgrootboek.push({"description": regel.nummer+" : "+ regel.omschrijving +"  ("+regel.rekeningCode+")", id:regel.nummer, "guid":regel.id})
                    })
                    this.snelstartgrootboek.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                    })
                    .catch(() => {
                    });
            }
            if(this.settings.boekhoudprogramma=="boekhoudgemak"){
                this.tabIndex = 0
            }
            if(this.settings.boekhoudprogramma=="eboekhouden"){
                this.tabIndex = 2
                await axios.get('/api/eboekhouden_getledger')
                .then((response) => {
                    this.eboekhoudengrootboek = []
                    console.log("response", response)
                    response.data.items.forEach(regel =>{
                        console.log(regel)
                        this.eboekhoudengrootboek.push({"description": regel.code+" : "+ regel.description +"  ("+regel.category+")", id:regel.id, "guid":regel.id})
                    })
                    this.eboekhoudengrootboek.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                    })
                    .catch(() => {
                    });
                await axios.get('/api/eboekhouden_invoicetemplate')
                .then((response) => {                    
                    this.eboekhoudenfactuurtemplates = [];
                    console.log("response", response)
                    response.data.items.forEach(regel =>{
                        console.log(regel)
                        this.eboekhoudenfactuurtemplates.push({"description": regel.name, id:regel.id, "guid":regel.id})
                    })
                    this.eboekhoudenfactuurtemplates.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                    })
                    .catch(() => {
                    });                    

            }
            
        },
        async sync_finqle(){
            let result = await axios.get("/api/finqledebtors")
            console.log(result)
            if(result.status==200){
                this.synclog = "Debiteuren vanaf het Finqle portaal opgehaald<br>"

                result.data.data.debtors.data.forEach(d => {
                    console.log(d)
                    let company = this.companies.filter(c => c.kvkcode == d.company.registrationNumber)[0]
                    if(company){
                        this.synclog += d.company.officialName+ " gesynchonieerd.<br>"
                        company.finqle = d.id
                        console.log(company)
                        this.save_company(company.id)
                        setTimeout(()=>{console.log("d")}, 100)

                    }

                })
            } else {
                this.synclog = "Er ging iets mis met het ophalen van de debiteuren vanaf het Finqle portaal"
            }
        }
    },
    watch:{
        // boekhoudprogramma(){
        //     console.log(">>",this.boekhoudprogramma)
        //     if(this.settings.boekhoudprogramma=="snelstart"){
        //         this.tabIndex = 1
        //     }
        //     if(this.settings.boekhoudprogramma=="boekhoudgemak"){
        //         this.tabIndex = 0
        //     }
            

        // }
    }

}
</script>